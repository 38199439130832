@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./assets/fonts/monserrat/static/Montserrat-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./assets/fonts/monserrat/static/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBolt';
    src: url('./assets/fonts/monserrat/static/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('./assets/fonts/monserrat/static/Montserrat-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./assets/fonts/monserrat/static/Montserrat-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RF-Dewi-ex-Regular';
    src: url('./assets/fonts/rfdewi/RFDewiExpanded-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'RF-Dewi-ex-Light';
    src: url('./assets/fonts/rfdewi/RFDewiExpanded-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RF-Dewi-ex-Bold';
    src: url('./assets/fonts/rfdewi/RFDewiExpanded-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
